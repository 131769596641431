export const diseaseJson = {
  statusCode: "000000",
  desc: "请求成功",
  result: {
    diseaseList: [
      {
        diseaseID: 41,
        name: "小儿感冒",
        alias: [
          " 小儿急性上呼吸道感染",
          "小儿上感",
          "急性鼻咽炎",
          "急性扁桃体炎",
        ],
        medicalInsurance: "非医保疾病",
        siteOfDisease: ["咽喉"],
        infectiousness: "无传染性",
        typicalSymptom: "打喷嚏,流鼻涕,新生儿鼻塞,发烧,流清涕",
      },
      {
        diseaseID: 48,
        name: "感冒",
        alias: [
          " 急性鼻咽炎",
          "急性上呼吸道感染",
          "上感",
          "卡他性鼻炎",
          "普通感冒",
          "伤风",
        ],
        medicalInsurance: "非医保疾病",
        siteOfDisease: ["鼻", "咽喉"],
        infectiousness: "有传染性",
        typicalSymptom: "打喷嚏,发烧,咳嗽,怕冷,流鼻涕",
      },
      {
        diseaseID: 270,
        name: "感冒",
        alias: [
          " 急性鼻咽炎",
          "急性上呼吸道感染",
          "上感",
          "卡他性鼻炎",
          "普通感冒",
          "伤风",
        ],
        medicalInsurance: "非医保疾病",
        siteOfDisease: ["鼻", "咽喉"],
        infectiousness: "有传染性",
        typicalSymptom: "打喷嚏,发烧,咳嗽,怕冷,流鼻涕",
      },
      {
        diseaseID: 274,
        name: "小儿流行性感冒",
        alias: [" 小儿感冒", "小儿流感"],
        medicalInsurance: "非医保疾病",
        siteOfDisease: ["咽喉"],
        infectiousness: "无传染性",
        typicalSymptom: "怕冷,流鼻涕,咽痛,吞咽痛,咽痛声哑",
      },
      {
        diseaseID: 790,
        name: "老年人流行性感冒",
        alias: [" 老年流行性感冒"],
        medicalInsurance: "非医保疾病",
        siteOfDisease: ["鼻", "肺"],
        infectiousness: "有传染性",
        typicalSymptom: "鼻塞,咳嗽,呼吸音粗糙,干咳,寒战",
      },
      {
        diseaseID: 929,
        name: "副流行性感冒",
        alias: [" 副流感", "副流感病毒感染", "类流感", "仙台病毒感染"],
        medicalInsurance: "非医保疾病",
        siteOfDisease: ["鼻", "气管"],
        infectiousness: "有传染性",
        typicalSymptom: "发烧,咽痛,流鼻涕,咳嗽,恶寒",
      },
      {
        diseaseID: 1697,
        name: "小儿嗜血性流行性感冒杆菌脑膜炎",
        alias: [" 流感杆菌脑膜炎"],
        medicalInsurance: "非医保疾病",
        siteOfDisease: ["颅脑", "其他"],
        infectiousness: "有传染性",
        typicalSymptom: "脑膜刺激征,颈强直,食欲不振,角弓反张,面色苍白",
      },
      {
        diseaseID: 1782,
        name: "病毒性感冒",
        alias: [""],
        medicalInsurance: "非医保疾病",
        siteOfDisease: ["头部", "鼻"],
        infectiousness: "有传染性",
      },
      {
        diseaseID: 4293,
        name: "胃肠型感冒",
        alias: [" 胃肠感冒"],
        medicalInsurance: "非医保疾病",
        siteOfDisease: ["肠", "胃"],
        infectiousness: "无传染性",
      },
      {
        diseaseID: 5210,
        name: "风热感冒",
        alias: [""],
        medicalInsurance: "非医保疾病",
        siteOfDisease: ["全身"],
        infectiousness: "无传染性",
        typicalSymptom: "发烧,头痛,咽喉疼痛,咳嗽,痰呈粘液脓性",
      },
      {
        diseaseID: 5219,
        name: "风寒感冒",
        alias: [""],
        medicalInsurance: "非医保疾病",
        siteOfDisease: ["全身"],
        infectiousness: "无传染性",
        typicalSymptom: "头痛,流清涕,怕冷,头重如裹,手足逆冷",
      },
      {
        diseaseID: 5333,
        name: "夏季感冒",
        alias: [" 暑湿感冒"],
        medicalInsurance: "非医保疾病",
        siteOfDisease: ["全身"],
        infectiousness: "无传染性",
        typicalSymptom: "怕冷,口淡无味,发烧,头痛,头胀",
      },
      {
        diseaseID: 5354,
        name: "热感冒",
        alias: [" 暑热感冒"],
        medicalInsurance: "非医保疾病",
        siteOfDisease: ["全身"],
        infectiousness: "无传染性",
        typicalSymptom: "浮脉,高热寒战,口干,涕唾稠粘,舌苔薄白",
      },
      {
        diseaseID: 5440,
        name: "感冒",
        alias: [
          " 急性鼻咽炎",
          "急性上呼吸道感染",
          "上感",
          "卡他性鼻炎",
          "普通感冒",
          "伤风",
        ],
        medicalInsurance: "非医保疾病",
        siteOfDisease: ["鼻", "咽喉"],
        infectiousness: "有传染性",
        typicalSymptom: "打喷嚏,发烧,咳嗽,怕冷,流鼻涕",
      },
      {
        diseaseID: 5469,
        name: "时行感冒",
        alias: [""],
        medicalInsurance: "非医保疾病",
        siteOfDisease: ["全身"],
        infectiousness: "有传染性",
        typicalSymptom: "高热寒战,剧烈头痛,流鼻涕,怕冷,疲乏",
      },
    ],
    totalCount: 16,
  },
};
